// Header Overlay  Background Image
@headerOverlayBgImg: '//d25ckoihpsjafc.cloudfront.net/rcitrials-assets/img/themes/bannerGreen.png';

// Header Right Side Nav Default Text Color
@customerNavDivColor: #ffffff;

// Header Right Side Nav Primary Header (e.g. Customer Name) Color
@customerNavDivPrimaryHeaderColor: #ffce3d;

// Header Right Side Nav Secondary Header (e.g. Customer Membership Number) Color
@customerNavDivSecondaryHeaderColor: #9f9f9f;

// Header Right Side Nav Link (e.g.My Account) Color
@customerNavAColor: #ffffff;

// Main Navigation Container Colors
@navPriTopAreaBorderColor: #666666;
@navPriTopAreaGradientStart: #289555;
@navPriTopAreaGradientEnd: #018136;

// Main Navigation Menu Options and Text Colors
@navPriTopNavigationParagraphColor: #ffffff;
@navPriTopNavigationOptionColor: #ffffff;
@navPriTopNavigationOptionOnColor: #ffce3d;
@navPriTopNavigationOptionOnBorderColor: #ffce3d;

// Welcome Page Text Colors
@introHeaderColor: #ffffff;
@introHeaderTextShadowColor: #003366;
@introSubtextColor: #002b66;

// Tab Off Colors
@tabTextColor: #ffffff;
@tabBorderColor: #666666;
@tabGradientStart: #8e8e8e;
@tabGradientEnd: #505050;

// Tab On/Hover Colors
@tabOnTextColor: #000000;
@tabOnBorderColor: #ffc500;
@tabOnGradientStart: #ffda83;
@tabOnGradientEnd: #ffc53e;

// Search Widget - Advanced Search Button Bottom Area Color
@searchWidgetAdvSearchBgColor: #032d68;

// Search Widget - Main Container Area Colors
@searchWidgetContainerGradientStart: #424242;
@searchWidgetContainerGradientEnd: #333333;

// Search Widget Header Text Color
@searchWidgetHeaderColor: #ffffff;

// Search Widget Text Color
@searchWidgetTextColor: #9f9f9f;

// Search Widget Month - Standard Color
@searchWidgetMonthGradientStart: #72a8f7;
@searchWidgetMonthGradientEnd: #498ce7;

// Search Widget Month - Select and Hover Colors
@searchWidgetMonthOnGradientStart: #ffce61;
@searchWidgetMonthOnGradientEnd: #fec234;

// Search Widget Month - Disabled Color
@searchWidgetMonthDisabledGradientStart: #bfbfbf;
@searchWidgetMonthDisabledGradientEnd: #afafaf;
